body{
  -webkit-font-smoothing: antialiased !important;
  color: #34495e !important;
  font-family: Source Sans Pro,Helvetica Neue,Arial,sans-serif !important;
  font-size: 15px !important;
  letter-spacing: 0 !important;
  margin: 0 !important;
  overflow-x: hidden !important;
  background-color: white !important;
}


.codeBox{
  text-align: left;
}

.inline, .inline pre {
  display: inline;;
}

.MuiListItem-root{
  padding: 5px 0 0 10px !important;
}

.menuLink{
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menuLink span{
  padding-left: 15px;
  color: #505d6b;
  font-size: 14px !important;
  font-weight: 400;
  overflow: hidden;
}
  table td, table th{
    padding: 5px;
  }

 table,th, td {
  border: 1px solid black;
  border-collapse: collapse;
}


